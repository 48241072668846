// Login
/**
 * Login form
 * - use a prop to show or hide the password reset link, since reset emails are not whitelabeled
 *
 * @packageDocumentation
 */
import React, { useContext, useEffect, useState } from 'react';

import { LoginProps } from './Login.types';
// routing
import * as ROUTES from 'constants/routes';
import { useRouter } from 'next/router';
import Link from 'next/link';
// form handling & error messages
import { useForm } from 'react-hook-form';
import { emailPattern } from 'lib/helpers';
import toast from 'react-hot-toast';
// auth state
import AuthContext from 'lib/context/auth/context';

const Login: React.FC<LoginProps> = ({ allowPasswordReset = false }) => {
  const { user } = useContext(AuthContext);
  const router = useRouter();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const defaultValues = { email: '', password: '' };
  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, isDirty, errors },
  } = useForm<{
    email: string;
    password: string;
  }>({
    defaultValues,
    mode: 'onChange',
  });

  useEffect(() => {
    if (user) {
      // redirect to dashboard page if user is logged in
      router.replace(`${ROUTES.DASHBOARD}`);
    }
  }, [user]);

  async function onSubmit(data) {
    /** Dynamically load firebase, since this is client-side code only */
    const firebase = (await import('lib/firebase')).default;

    try {
      setIsSubmitting(true);
      await firebase
        .auth()
        .signInWithEmailAndPassword(data.email, data.password);
    } catch (error) {
      setIsSubmitting(false);
      toast.error(error.message);
    }
  }

  return (
    <div data-testid='Login' className='w-full'>
      <section className='m-auto max-w-sm p-4 rounded-md bg-white shadow-md'>
        <h3 className='text-2xl text-center'>Log In</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='form'>
            <label htmlFor='email'>
              <span className='form__label'>Email Address</span>
              <input
                type='email'
                id='email'
                placeholder='you@business.com'
                autoFocus
                required
                className={`form__input ${
                  errors.email && 'form__input--error'
                }`}
                {...register('email', {
                  required: true,
                  pattern: emailPattern,
                })}
              />
              {errors.email && (
                <p className='form__error'>
                  {errors.email.type === 'required' && 'Email is required'}
                  {errors.email.type === 'pattern' && 'Enter a valid email'}
                </p>
              )}
            </label>
            <label htmlFor='password'>
              <span className='form__label'>Password</span>
              <input
                type='password'
                id='password'
                placeholder='Enter your password'
                required
                className={`form__input ${
                  errors.password && 'form__input--error'
                }`}
                {...register('password', {
                  required: true,
                })}
              />
              {errors.password && (
                <p className='form__error'>Password is required</p>
              )}
            </label>
            <button
              disabled={!isDirty || !isValid || isSubmitting}
              type='submit'
              className='p-2 bg-primary text-white disabled:opacity-50'
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
            {/** No password forget link for custom pages, since those links are not whitelabeled
             * the Firebase auth shows the TrustScout firebase app */}
            {allowPasswordReset && (
              <p className='text-center text-sm underline text-primary'>
                <Link href={ROUTES.FORGOT_PASSWORD}>Forgot your password?</Link>
              </p>
            )}
          </div>
        </form>
      </section>
      <div />
    </div>
  );
};

export default Login;
