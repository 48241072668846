import { createContext } from 'react';
import firebase from 'lib/firebase';

interface IAuthContext {
  user: firebase.User;
}

/**
 * context of logged-in user
 */
const AuthContext = createContext<IAuthContext>({
  user: undefined,
});

export default AuthContext;
