// Loader
/**
 * Horizontal infinite loading bar used throughout the app
 *
 * @packageDocumentation
 */
import React from "react";

import { LoaderProps } from "./Loader.types";

const Loader: React.FC<LoaderProps> = ({ className }) => {
  return (
    <div data-testid="Loader" className="relative">
      <div className={`w-full z-50 ${className}`}>
        <div className="absolute w-full h-2 overflow-x-hidden">
          <div className="absolute w-full opacity-40 bg-blue-600 h-1"></div>
          <div className="absolute bg-blue-600 h-1 loader--inc"></div>
          <div className="absolute bg-blue-600 h-1 loader--dec"></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
