export const FORGOT_PASSWORD = '/reset';

/** public pages */
export const LOGIN = '/login';
export const FEEDBACK = '/feedback';
export const INVITE = '/invite';
export const REVIEWS = '/reviews';
export const RECORD = '/record';
export const SCHEDULE = '/schedule';
export const CHECKOUT = '/checkout';
export const THANK_YOU = '/thank-you';
export const SALES = '/s';
export const VIRTUAL_SALES = '/virtual-salesrep';

/** Dashboard */
export const DASHBOARD = '/dashboard';
export const DASHBOARD_USER = `${DASHBOARD}/users`;
export const DASHBOARD_SETTINGS = `${DASHBOARD}/settings`;
export const DASHBOARD_BRANDING = `${DASHBOARD}/branding`;
export const DASHBOARD_LINKS = `${DASHBOARD}/urls`;
export const DASHBOARD_ACCOUNT = `${DASHBOARD}/account`;
export const DASHBOARD_MANAGE = `${DASHBOARD}/manage`;
export const DASHBOARD_TRUSTFUNNEL = `${DASHBOARD}/trustfunnel`;
export const DASHBOARD_SCHEDULE = `${DASHBOARD}${SCHEDULE}`;
export const DASHBOARD_THANK_YOU = `${DASHBOARD}${THANK_YOU}`;
export const DASHBOARD_REVIEWS = `${DASHBOARD}${REVIEWS}`;
export const DASHBOARD_CHECKOUT = `${DASHBOARD}${CHECKOUT}`;
export const DASHBOARD_SALES = `${DASHBOARD}${SALES}`;
export const DASHBOARD_COLLECT = `${DASHBOARD}/collect`;
export const DASHBOARD_VSL = `${DASHBOARD}/record-vsl`;
export const DASHBOARD_WIDGET = `${DASHBOARD}/widget`;
export const DASHBOARD_INVITE = `${DASHBOARD}${INVITE}`;
export const DASHBOARD_SUPPORT = `${DASHBOARD}/support`;
export const DASHBOARD_SETUP = `${DASHBOARD}/setup`;
export const DASHBOARD_FEEDBACK = `${DASHBOARD}${FEEDBACK}`;
export const DASHBOARD_RESOLUTION = `${DASHBOARD}/resolve`;
export const DASHBOARD_QRCODE = `${DASHBOARD}/qrcode`;
export const DASHBOARD_VIRTUAL_SALES = `${DASHBOARD}${VIRTUAL_SALES}`;
