/**
 * white-labeled signin page
 */
import { GetStaticProps, GetStaticPaths } from 'next';
import { useRouter } from 'next/router';
import Head from 'next/head';
import DefaultErrorPage from 'next/error';

// auth & data handling
import { ICompany } from '@types';
import { getCompanyInfo } from 'lib/firebase/pages';
// page branding
import BrandedPage from 'components/common/BrandedPage';
import BrandedPageHead from 'components/common/BrandedPageHead';
// page layout
import Login from 'components/public/Login';
// page elements
import Loader from 'components/common/Loader';
import isAccountPaused from 'lib/helpers/isAccountPaused';
import PausedPublicPageDefault from 'components/public/PausedPublicPageDefault';

type ClientLoginProps = {
  company: ICompany;
};

export default function ClientLogin({ company }: ClientLoginProps) {
  const router = useRouter();

  // If the page is not yet generated, this will be displayed
  // initially until getStaticProps() finishes running
  if (router.isFallback) {
    return <Loader />;
  }

  // This includes setting the noindex header because static files always return a status 200 but the rendered not found page page should obviously not be indexed
  if (!company) {
    return (
      <>
        <Head>
          <meta name='robots' content='noindex' />
        </Head>
        <DefaultErrorPage statusCode={404} />
      </>
    );
  }

  const branding = company.branding;
  const name = company.name;
  return (
    <>
      <BrandedPageHead
        pageData={{}} // there is no custom page data for this page
        pageName={`${name} Login`}
      />
      <BrandedPage
        logo={branding?.logo || null}
        colorPrimary={branding?.colorPrimary || null}
        name={name || ''}
      >
        {isAccountPaused(company) ? (
          <PausedPublicPageDefault companyName={company.name} />
        ) : (
          <Login />
        )}
      </BrandedPage>
    </>
  );
}

// This function gets called at build time
export const getStaticProps: GetStaticProps = async ({ params }) => {
  // Get company data
  const company: ICompany = await getCompanyInfo(params.companySlug as string);

  // Pass the props to the page component
  return {
    props: {
      company: company,
    },
    // we will attempt to re-generate the page:
    // - when a request comes in
    // - at most once every second
    revalidate: 1,
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    /**  don't pre-render any routes at build time */
    paths: [],
    /**
     * fallback: 'blocking' mode will cause unknown paths to be rendered
     * on-demand ("SSR") without the static (placeholder) fallback.
     * this is better for SEO purposes, see this discussion: https://github.com/vercel/next.js/discussions/11552
     */
    fallback: 'blocking',
  };
};
