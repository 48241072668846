/**
 * Default page component display for public company pages that require configuration
 * @packageDocumentation
 */
import React from 'react';
import Link from 'next/link';

const PausedPublicPageDefault: React.FC<{
  companyName: string;
}> = ({ companyName }) => {
  return (
    <div
      data-testid='PausedPublicPageDefault'
      className='flex flex-col justify-start items-center gap-10'
    >
      <h3 className='text-xl'>
        <span className='font-semibold'>{companyName}</span>'s account is
        currently paused. If this is your company, please{' '}
        <Link href={`${process.env.NEXT_PUBLIC_APP_URL}/login`}>
          <a className='underline text-primary'>log in</a>
        </Link>{' '}
        to learn more.
      </h3>
    </div>
  );
};

export default PausedPublicPageDefault;
