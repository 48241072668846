import { ICompany, IPageVirtualSales } from '@types';
import { customAlphabet } from 'nanoid';

/**
 * validate the email
 * @param str - the email input
 * @returns - true if email is valid
 */
export function validateEmail(str: string) {
  const re =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return !!re.test(str);
}
export const emailPattern =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const planIdPattern = /\d{5}-\d{5}$/;

/**
 * validate that a complete URL was provided
 * @param str - the URL input
 * @returns - true if URL is valid
 */
export function validateURL(str: string) {
  var re = new RegExp(
    '^(https?:\\/\\/)' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z:\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!re.test(str);
}

export const urlPattern = new RegExp(
  '^(https?:\\/\\/)' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z:\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i'
);

export const domainPattern = new RegExp(
  '^(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}',
  'i'
);

/**
 * https://gist.github.com/codeguy/6684588
 *
 * To make the slug unique, add random strings to it using nanoid package.
 *
 * With nanoid of value 10,  ~17 thousand years needed, in order
 * to have a 1% probability of at least one collision. See
 * the link https://zelark.github.io/nano-id-cc/
 */
type ConfigOptions = { separator?: string; unique?: boolean };

export function sluggy(
  str: string,
  config: ConfigOptions = { unique: false }
): string {
  // The default characters use by nanoid included dash (-) and underscore (_)
  // However I do not want to include those characters.
  const cha = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const nanoid = customAlphabet(cha, 10);

  const slug = config.unique ? `${str} - ${nanoid()}` : str;
  const separator = config.separator === undefined ? '-' : config.separator;

  return slug
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, separator); // separator
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function virtualSalesPageConfigured(
  company: ICompany,
  virtualSales: IPageVirtualSales
) {
  return (
    company?.features?.virtualSales &&
    virtualSales &&
    !virtualSales.excludeFromCollectionFlow &&
    virtualSales?.headline &&
    virtualSales?.description &&
    virtualSales?.ctaButtonURL
  );
}
